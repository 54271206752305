/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Popover, Transition, Disclosure } from "@headlessui/react";
import BaseLink from "../BaseLink/BaseLink";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const HeaderMenuDropdown = ({ id, label, childItems, index }) => {
  return (
    <>
      <Popover as="li" key={`parent-link-${id}`} className="hidden lg:block">
        {({ open }) => (
          <>
            <Popover.Button
              className={classNames(
                // open ? "text-gray-900" : "text-gray-500",
                "group inline-flex items-center rounded-md text-base font-semibold uppercase leading-5 hover:text-[#C61D23] xl:tracking-widest"
              )}
            >
              <span>{label}</span>
              <ChevronDownIcon
                className={classNames(
                  open ? "rotate-180" : "rotate-0",
                  "ml-1.5 h-6 w-6 transform text-[#C61D23]"
                )}
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 xl:translate-y-7 lg:translate-y-3"
              enterTo="opacity-100 xl:translate-y-9 lg:translate-y-5"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 xl:translate-y-9 lg:translate-y-5"
              leaveTo="opacity-0 xl:translate-y-7 lg:translate-y-3"
            >
              <Popover.Panel className="absolute inset-x-0 z-20 min-h-[232px] transform bg-white shadow-lg">
                <div className="relative shadow-inner">
                  <div className="container py-16">
                    <div className="flex items-start justify-between">
                      <div>
                        <h3 className="text-5xl font-bold leading-tight">
                          {label}
                        </h3>
                        <div className="h-[5px] w-24 bg-[#C61D23]"></div>
                      </div>

                      <ul
                        role="list"
                        className="grid grid-cols-3 gap-x-14 gap-y-4 xl:gap-x-20"
                      >
                        {childItems.nodes.map(({ id, label, path }, idx) => (
                          <li key={id} className={`w-[160px]`}>
                            <BaseLink
                              href={path}
                              className="block hover:text-[#C61D23]"
                            >
                              {label}
                            </BaseLink>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>

      <Disclosure
        as="li"
        key={`parent-link-mobile-${id}`}
        className="mb-11 block lg:hidden"
      >
        {({ open }) => (
          <>
            <Disclosure.Button
              className={classNames(
                open ? "text-gray-900" : "text-gray-500",
                "group inline-flex items-center rounded-md text-base font-semibold uppercase hover:text-[#C61D23] w-full"
              )}
              id={`link-btn-${id}`}
            >
              <span className="grow text-left">{label}</span>
              <ChevronDownIcon
                className={classNames(
                  open ? "rotate-180" : "rotate-0",
                  "ml-1.5 h-6 w-6 transform text-[#C61D23]"
                )}
                aria-hidden="true"
              />
            </Disclosure.Button>

            <Disclosure.Panel className="">
              <div className="relative">
                <div className="py-5">
                  <div className="">
                    <ul role="list" className="grid grid-cols-1 gap-y-3 pl-2">
                      {childItems.nodes.map(({ id, label, path }, idx) => (
                        <li key={id} className={``}>
                          <BaseLink
                            href={path}
                            className="block hover:text-[#C61D23]"
                          >
                            {label}
                          </BaseLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

HeaderMenuDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  childItems: PropTypes.object.isRequired,
  index: PropTypes.any
};

export default HeaderMenuDropdown;
