const React = require("react")
import "./src/css/style.css";
import "./src/css/menu.scss";
import Layout
  from "./src/components/layout";

export const wrapRootElement = ({ element }) => element;

// export const wrapPageElement = ({ element, props }) => {
//   return <Layout {...props}>{element}</Layout>
// }

export const onClientEntry = () => {
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
  }
};

export const shouldUpdateScroll = () => {
  return false;
}

export const onRouteUpdate = ({location, prevLocation}) => {
  if (!location.hash && typeof window != 'undefined') {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 0)
  }

  if (location && location.hash) {
    setTimeout(() => {
      if (document.querySelector(`${location.hash}`) && document.querySelector('header')) {
        const item = document.querySelector(`${location.hash}`).offsetTop;
        const navHeight = document.querySelector('header').clientHeight;
        setTimeout(() => {
          window.scrollTo({
            top: item - navHeight,
            behavior: "smooth",
          })
        }, 0)
      }
    }, 500)
  }
}