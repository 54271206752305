/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import TypeformButton from "../TypeformButton/TypeformButton";

const BaseLink = ({
  children,
  href,
  target,
  enforceTarget,
  className,
  noButton,
  ...otherProps
}) => {
  const EXTERNAL_LINK = /^(https?)/;
  const OTHER_EXTERNAL_LINK = /^(http?)/;
  const NON_ABSOLUTE_LINK = /^(mailto:|tel:)/;
  const INTERNAL_LINK = /^(#)/;

  // Create a regex to detect when a link starts with a slash and includes a hash somewhere in the string
  const SLASH_HASH_LINK = /^\/.*#.*$/;

  const linkProps = {
    ...otherProps,
    target: enforceTarget ? target : undefined,
  };

  // if onClick is passed, then create a button
  if (otherProps.onClick) {
    if(!noButton) {
      return (
        <button {...otherProps}
                className={`cursor-pointer ${className}`}>
          {children}
        </button>
      );
    }
    else {
      return (
        <span {...otherProps}>{children}</span>
      )
    }
  }

  if (!href) return children;

  // If the link is equal to #lead-form, then it's a typeform link
  if (href === "#lead-form") {
    return (
      // On click, we want to open the typeform modal
      <TypeformButton {...otherProps} className={`cursor-pointer ${className}`}>
        {children}
      </TypeformButton>
    );
  }

  if (href && href.match(SLASH_HASH_LINK)) {
    return (
      // <AnchorLink to={href} {...otherProps} className={className} stripHash>
      //   {children}
      // </AnchorLink>
      <a href={href} className={className}>
        {children}
      </a>
      // <Link to={href} {...otherProps} className={className}>
      //   {children}
      // </Link>
    );
  }

  if (href && (href.match(EXTERNAL_LINK) || href.match(OTHER_EXTERNAL_LINK))) {
    return (
      <a
        href={href}
        rel="noopener noreferrer"
        {...otherProps}
        target={target}
        className={className}
      >
        {children}
      </a>
    );
  }

  if (href && href.match(NON_ABSOLUTE_LINK)) {
    return (
      <a href={href} {...linkProps} className={className}>
        {children}
      </a>
    );
  }

  return (
    <Link to={`${href}`} {...linkProps} className={className}>
      {children}
    </Link>
  );
};

BaseLink.defaultProps = {
  target: "_blank",
  href: "",
  noButton: false
};

BaseLink.propTypes = {
  children: PropTypes.any,
  href: PropTypes.any,
  target: PropTypes.string,
  enforceTarget: PropTypes.bool,
  className: PropTypes.string,
  otherProps: PropTypes.any,
  noButton: PropTypes.bool
};

export default BaseLink;
