import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

export default function Image({
  src,
  alt,
  imgClassName,
  placeholder = "blurred",
  ...other
}) {
  return (
    <>
      <GatsbyImage
        image={getImage(src)}
        alt={alt}
        imgClassName={imgClassName}
        placeholder={placeholder}
        {...other}
      />
    </>
  );
}

Image.propTypes = {
  src: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
  imgClassName: PropTypes.string,
  placeholder: PropTypes.string,
};
