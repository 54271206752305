import React from "react";
import PropTypes from "prop-types";

export const ArrowRight = ({ className }) => {
  return (
    <svg
      className={className}
      width="10"
      height="15"
      viewBox="0 0 10 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.656831 1.84292L6.31369 7.49978L0.656831 13.1566L2.07104 14.5708L7.7279 8.91399L9.14211 7.49978L7.7279 6.08556L2.07104 0.428711L0.656831 1.84292Z"
      />
    </svg>
  );
};

ArrowRight.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
