/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import BaseLink from "../BaseLink/BaseLink";
import HeaderMenuDropdown
  from "./HeaderMenuDropdown";

const HeaderSubMenu = ({ id, label, childItems }) => {
  return (
    <li className="menu-item">
      <input type="checkbox" id={`nav_${id}`} className="mm-checkbox" />
      <label htmlFor={`nav_${id}`} className="mm-label">
        <a aria-haspopup="true" className="menu-link menu-bar-link">{label}</a>
        <span className="chevron" />
      </label>
      <ul className="megamenu">
        <div className="megacontainer shadow-inner">
          <div className="megapadding container">
            <div className="submenu">
              <div>
                <h3 className="text-5xl font-bold leading-tight">{label}</h3>
                <div className="h-[5px] w-24 bg-[#C61D23] redline"></div>
              </div>
              <ul role="list" className="submenuitems">
                {childItems.nodes.map((item, itemIndex) => {
                  return (
                    <li key={`menu${id}_${label}_${itemIndex}`} className="megamenu-link">
                      <BaseLink
                        href={item.path}
                        className="block hover:text-[#C61D23]"
                      >{item.label}</BaseLink>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </ul>
    </li>
  )
}

HeaderSubMenu.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  childItems: PropTypes.object.isRequired,
};

export default HeaderSubMenu;
