/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { Parallax, Background } from "react-parallax";
import { StaticImage } from "gatsby-plugin-image";
import Button from "../Button/ButtonV2";
import loadable from '@loadable/component';
const CustomHubspotForm = loadable(() => import("../CustomHubspotForm/CustomHubspotForm"));

const FooterParallax = ({ title, text, button, footerHubspotFormId }) => {
  //console.log('title: ', title);
  return (
    <section>
      <Parallax
        strength={400}
        bgClassName="bgClassName"
        contentClassName="contentClassName"
        className="[&>.react-parallax-background-children]:w-full"
      >
        <Background className="custom-bg">
          <div className="h-full w-full">
            <>
              <StaticImage
                src="../../assets/images/backgrounds/red-background-parallax.jpeg"
                alt="Background Parallax"
                layout="fullWidth"
                quality={100}
                className="!hidden lg:!block"
              />
              <StaticImage
                src="../../assets/images/backgrounds/red-background-parallax-mobile.jpg"
                alt="Background Parallax"
                layout="fullWidth"
                quality={100}
                className="!block lg:!hidden"
              />
            </>
          </div>
        </Background>
        <div className={`flex min-h-[550px] pt-[120px]`}>
          <div className="xpb-[1vw] container text-center text-white md:text-left">
            {title && (
              <h2
                className={`text-3xl font-bold tracking-wider md:text-5xl md:leading-tight`}
                dangerouslySetInnerHTML={{ __html: title }}
              ></h2>
            )}

            {text && (
              <div
                className="mt-6 text-xl font-medium uppercase leading-6 tracking-wider md:mt-14 md:text-xl md:leading-6"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            )}

            {(button.label && button.link) && (
              <div className="mt-11 md:mt-7">
                <Button href={button.link} arrowRight color="black">
                  {button.label}
                </Button>
              </div>
            )}

              {footerHubspotFormId && (
                  <div className={`w-[40%]`} id={`form_${footerHubspotFormId}`}>
                      <CustomHubspotForm
                          portalId="7676851"
                          formId={footerHubspotFormId}
                          loading={<div>Loading...</div>}
                      ></CustomHubspotForm>
                  </div>
              )}
          </div>
        </div>
      </Parallax>
    </section>
  );
};

FooterParallax.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  blackBackground: PropTypes.bool,
  button: PropTypes.object,
  subtitle: PropTypes.string,
  short: PropTypes.bool,
    footerHubspotFormId: PropTypes.string
};

export default FooterParallax;
