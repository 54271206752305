import React, { useState } from "react";
import { PropTypes } from "prop-types";
export const TypeformContext = React.createContext(false);

const TypeformProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <TypeformContext.Provider
      value={[showModal, setShowModal]}>
      {children}
    </TypeformContext.Provider>
  );
};

TypeformProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TypeformProvider;
