/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import HeaderMenu from "./HeaderMenu";
import BaseLink from "../BaseLink/BaseLink";
import Button from "../Button/ButtonV2";
import PropTypes from "prop-types";
import HeaderCssMenu
  from "./HeaderCssMenu";

const Header = ({ headerRef }) => {
  const data = useStaticQuery(graphql`
    query {
      wpMenu(slug: { eq: "header-menu-2023" }) {
        id
        name
        slug
        menuItems {
          nodes {
            id
            label
            parentDatabaseId
            parentId
            path
            childItems {
              nodes {
                label
                url
                path
                id
              }
            }
          }
        }
      }
    }
  `);

  const menu = data.wpMenu.menuItems.nodes
    ? data.wpMenu.menuItems.nodes.filter(
        (menuItem) => menuItem.parentId === null
      )
    : [];

  return (
    <header
      className={`fixed top-0 left-0 z-30 flex w-full items-center bg-white py-5 px-5 shadow-lg xl:px-12 header`}
      ref={headerRef}
      style={{
        height: '96px'
      }}
    >
      <div className="flex-0 mr-2">
        <BaseLink href="/">
          <StaticImage
            src="../../assets/images/header/disruptive-logo.png"
            alt="Disruptive Logo"
            width={218}
            // loading="eager"
            placeholder="none"
            className="w-[170px] lg:w-[218px]"
            formats={["webp", "png"]}
          />
        </BaseLink>
      </div>

      <HeaderCssMenu menu={menu} />
      {/*<HeaderMenu menu={menu} />*/}

      {/*<div className="hidden lg:ml-3 lg:block xl:ml-12">*/}
      {/*  <Button*/}
      {/*    href="#lead-form"*/}
      {/*    className="py-3 px-4 xl:py-5 xl:px-7"*/}
      {/*    color="red"*/}
      {/*    bordered*/}
      {/*  >*/}
      {/*    Let’s Talk*/}
      {/*  </Button>*/}
      {/*</div>*/}
    </header>
  );
};

export default Header;

// Set PropTypes
Header.propTypes = {
  getHeaderHeight: PropTypes.func,
  headerRef: PropTypes.object,
};
