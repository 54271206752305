import React, { useContext } from "react";
import PropTypes from "prop-types";
import { TypeformContext } from "../TypeformContext/TypeformContext";

const TypeformButton = ({ className, children }) => {
  // eslint-disable-next-line no-unused-vars
  const [showModal, setShowModal] = useContext(TypeformContext);
  const onShowModal = () => {
    setShowModal(true)
    if(typeof window != 'undefined') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'hubspotFormShow'
      })
    }
  }

  return (
    <span onClick={() => onShowModal()} className={className} role="button">
      {children}
    </span>
  );
};

export default TypeformButton;

TypeformButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
