/* eslint-disable no-unused-vars */
import React, {lazy} from "react";
import PropTypes from "prop-types";

const BaseLink = lazy(() => import("../BaseLink/BaseLink"));
import { ArrowRight } from "../../assets/svgs/arrowRightNew";

const Button = ({
  children,
  arrowRight = false,
  href,
  bordered = false,
  color = "black",
  className,
  ...otherProps
}) => {
  color = color.toLowerCase();
  return (
    <BaseLink
      href={href}
      className={
        `group relative inline-block py-3 px-5 text-base font-semibold uppercase leading-4
        tracking-widest transition-all duration-300 ease-in-out md:py-[21px] md:px-[30px] ` +
        className
      }
      {...otherProps}
    >
      {color === "black" && (
        <div
          className={`absolute top-0 left-1/2 h-full w-full -translate-x-1/2 transform border-[3px] 
            border-[#1B1C1C] bg-[#1B1C1C] transition-all duration-300 ease-in-out group-hover:w-[103%] ${
              bordered
                ? "group-hover:bg-transparent"
                : "group-hover:border-white group-hover:bg-white"
            }
          `}
        />
      )}
      {color === "red" && (
        <div
          className={`absolute top-0 left-1/2 h-full w-full -translate-x-1/2 
          transform bg-gradient-to-t from-[#800507] to-[#DA2128] transition-all duration-300 ease-in-out group-hover:w-[calc(100%+6px)] 
           ${
             bordered
               ? "border-[0px] border-solid border-[#C61D23] border-opacity-0 group-hover:border-[3px] group-hover:border-opacity-100 group-hover:from-transparent group-hover:to-transparent"
               : "group-hover:border-white group-hover:from-[#1B1C1C] group-hover:to-[#1B1C1C]"
           }`}
        />
      )}
      <span
        className={`relative transition-colors duration-300 ease-in-out ${
          color === "black"
            ? "text-white group-hover:text-[#1B1C1C] "
            : (color === "red") & bordered
            ? "text-white group-hover:text-[#C61D23]"
            : (color === "red") & !bordered
            ? "text-white"
            : ""
        }`}
      >
        {children}
        {arrowRight && (
          <ArrowRight
            className="ml-3 -mt-[4px] inline fill-current !align-middle transition-all 
            duration-300 ease-in-out group-hover:translate-x-2 md:ml-4"
          />
        )}
      </span>
    </BaseLink>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  arrowRight: PropTypes.bool,
  href: PropTypes.string,
  className: PropTypes.string,
  bordered: PropTypes.bool,
  color: PropTypes.string,
};

export default Button;
